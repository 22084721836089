

@font-face {
  font-family: Blinker-Regular;
  src: url(./fonts/Blinker-Regular.ttf);
}
@font-face {
  font-family: 'Digital Numbers';
  font-style: normal;
  font-weight: 400;
  src: local('Digital Numbers'), url('./fonts/DigitalNumbers-Regular.woff') format('woff');
}
@font-face {
  font-family: Blinker-Bold;
  src: url(./fonts/Blinker-Bold.ttf);
}

@font-face {
  font-family: Blinker-Thin;
  src: url(./fonts/Blinker-Thin.ttf);
}

@font-face {
  font-family: Blinker-Light;
  src: url(./fonts/Blinker-Light.ttf);
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100svw;
  height: 100vh;
  background-color: #182233;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  opacity: 1;
  visibility: visible;
  z-index: 1000;
  overflow: hidden !important;
}
/* .MuiBox-root{
  width: 90dvw !important;
} */
.loader.hidden {
  opacity: 0;
  visibility: hidden;
  overflow: hidden !important;
}

.hidden {
  /* overflow: hidden !important; */
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3; /* Light gray */
  border-top: 5px solid #0c2536; /* Blue */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



.App {
  text-align: center;
}

.ff-bold {
  font-family: Blinker-Bold !important;
}

* {
  scrollbar-color: #10F9B1 #FFFFFF20 !important;
  scrollbar-width: auto;
  /* border-radius: 20px;  */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.height {
  height: 500px !important;
}

body {
  font-family: Blinker-Regular !important;
  min-height: 50vh !important;
  height: auto !important;
  background: #182233 !important;
  color: #FFF;
  max-width: 100%;
  width: 100%;
}

#root {
  font-family: Blinker-Regular !important;
  width: 100% !important;
  
}

nav {
  width: 100%;
  /* max-width: 100%; */
}

.text-big {
  font-size: 86px !important;
}

.transition-04 {
  transition: 0.4s !important;
}

.Blinker-Thin {
  font-family: Blinker-Thin !important;
}

.mobile-menu {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: #182233 !important;
  backdrop-filter: blur(10px);
  position: absolute;
  top: -200vh;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000000000000000;
  transition: 0.5s;
}

.mobile-menu-content {
  gap: 20px !important;
  margin-left: 40px;
}

.isActiveNav{
color: #10F9B1 !important;
/* font-size:16px !important; */
font-weight: bolder !important;
font-family: Blinker !important;
}

.open-menu {
  top: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}





.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.MuiAccordion-rounded {
  border-radius: 20px !important;
}
@media screen and (max-width:991px) {

  #quote-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-family: blinker;
    margin-top: 0px !important;
  }
  
  #quote-text span {
    font-size: 14px !important;
    font-weight: 400;
  }

}
#quote-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-family: blinker;
  margin-top: 100px;
  /* margin-bottom: ; */
}

#quote-text> span {
  font-size: 20px !important;
  font-weight: 400;
}

.header-main {
  box-shadow: 0px 0px 10px #7a7d9c80;
}

.d-none {
  display: none !important;
}

.px-10 {
  margin-left: auto;
  margin-right: auto;
  max-width: 1100px !important;
  min-height: 620px;
  position: relative;
  padding-bottom: 20px;
}

.text-right {
  text-align: right !important;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 0;
  padding: 0;
}

.progress-bar-quote {
  max-width: 80% !important;
}

.flex-image {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 30px;
  width: 30%;
}

.quote-title {
  font-size: 41px !important;
}

.open-other-option {
  transform: rotate(-180deg);
  transition: 0.2s;
}

.close-other-option {
  transform: rotate(0deg);
  transition: 0.2s;
}

.other-option-item {
  text-align: left !important;
  display: flex;
  justify-content: left !important;
  border-radius: 8px !important;
  padding: 10px 0 !important;
  color: #182233 !important;
  transition: 0.3s !important;
  border: 1px solid #FFF !important;
}

.question-form-title {
  font-size: 56px !important;
}

.p-10 {
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1100px !important;
}

.other-box {
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  min-height: 700px;
  position: relative;
  max-width: 1100px;
  margin: 10px auto;
}
@media (max-width: 768px) {
  .btn-next {
    /* width: auto !important; */
    /* max-width: fit-content; */
    /* height: auto; */
    margin-top: 30px;
    /* background: linear-gradient(228.14deg, #51FFCA 7%, #D0F1E7 40.82%, #9FFDE0 76.45%); */
    background-color: #51FFCA !important;
    color: #01080A !important;
    font-family: Blinker-Light !important;
    font-weight: 300 !important;
    font-size: 36px !important;
    /* position: absolute; */
    bottom: 0;
    right: 0;
    /* padding: 10px 100px !important; */
    transition: 0.3s;
    width: 201px !important;
    height: 48px !important;
  }
}
.btn-next {
  /* width: auto !important; */
  /* max-width: fit-content; */
  /* height: auto; */
  margin-top: 30px;
  /* background: linear-gradient(228.14deg, #51FFCA 7%, #D0F1E7 40.82%, #9FFDE0 76.45%); */
  background-color: #51FFCA !important;
  color: #01080A !important;
  font-family: Blinker-Light !important;
  font-weight: 300 !important;
  font-size: 36px !important;
  /* position: absolute; */
  bottom: 0;
  right: 0;
  /* padding: 10px 100px !important; */
  transition: 0.3s;
  width: 310px ;
  height: 74px ;
}

.btn-next:hover {
  background: linear-gradient(225deg,#1BE4A6  20%, #9FFDE0 48%, #1BE4A6 78%);
}

.pt-form-title {
  padding-top: 105px !important;
}

.question-form {
  display: flex;
  flex-direction: column;
  gap: 26px;
  position: relative;
  padding-bottom: 100px;
}

.question-form-1 {
  display: flex;
  flex-direction: column;
  gap: 26px;
  position: relative;
  padding-bottom: 100px;
}

.question-form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 45%;
  font-family: Blinker-Regular !important;
  font-size: 24px !important;
  font-weight: 300 !important;
}

.form-business-flex {
  display: flex;
  gap: 60px;
  width: 100%;
  position: relative;
}

.w-50 {
  width: 50% !important;
}

.w-full {
  width: 100% !important;
}
input{

  color: white !important;
  /* padding: 10px !important; */
  /* border-radius: 8px !important; */
  /* border: 1px solid #6C757D !important; */
  /* appearance: none !important; */
  /* font-family: Blinker-Regular !important; */
  font-size: 16px !important;
  font-weight: 300 !important;
  border:1px solid #aaa  !important;
  /* background-color: rgba(255, 255, 255, 0.1) !important; */
}

.question-form-group>textarea {
  
  padding: 10px !important;
  border-radius: 8px !important;
  /* border: 1px solid #6C757D !important; */
  appearance: none !important;
  font-family: Blinker-Regular !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  color: white !important;
  /* border:1px solid #aaa  !important; */
  background-color: rgba(255, 255, 255, 0.1) !important;
}


.question-form-group input:focus {
  outline: none !important;
}

.error-text {
  color: #EA4335 !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  font-family: Blinker-Regular !important;
  /* text-align: center !important; */
}
@media (max-width: 768px) {
  .error-text {
    font-size: 14px !important;
  }
}
.btn-next-parent {
  /* width: 310px !important; */
  gap: 0 !important;
}

.text-2xl {
  font-size: 30px;
}

.h-20px {
  height: 20px !important;
  margin-bottom: 10px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.end-page {
  background-color: #657CDE;
  height: 100vh;
  margin-top: -10px !important;
  padding-top: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 70px;
}

.end-page-title {
  /* font-size: 68px !important; */
  font-weight: 700;
  font-family: Blinker-Regular !important;
  color: #FFF;
  text-align: center;
}

.end-page-card {
  background: linear-gradient(228.14deg, #1BE4A6 7%, #D0F1E7 40.82%, #9FFDE0 76.45%);
  padding: 70px 150px;
  width: 60%;
  text-align: center;
  border-radius: 49px;
  font-size: 45px !important;
  font-weight: 400 !important;
  font-family: Blinker-Regular;
  color: #1E1E1E !important;
}

.end-page-text {
  font-size: 45px !important;
  font-weight: 400;
  font-family: Blinker-Regular;
  color: #FFF;
  text-align: center;

}

.end-page-text-2 {
  font-size: 24px !important;
  font-weight: 300;
  font-family: Blinker-Light;
  color: #FFF;
}

.btn-header {
  box-shadow: 0px 19px 59px 0px #10F9B173;
}

.btn-header-purple:hover{
  background: linear-gradient(30deg, #D9B4FE 0, #D9B4FE 39%, #AD68F2 80%);
  color: #000 !important;
}

.btn-header:hover{
  background: linear-gradient(30deg, rgba(27,228,166,1) 20%, rgba(208,241,231,1) 50%, rgba(16,249,177,1) 80%);
}
.btn-header-purple {
  /* box-shadow: 0px 19px 59px 0px #10F9B173; */
  color: #fff !important;
}
.typing-wrapper {
  font-size: 76px;
  font-weight: 700;
  font-family: Blinker-Bold;
  text-align: start;
}

.header-image {
  background: url('./images/Header-Background.png');
  background-size: cover;
  position: absolute;
  z-index: 0;
  width: 75%;
  height: 115vh;
  right: -1%;
  opacity: 13%;
}
@media (max-width: 900px) {
  .header-image {
    width: 180vw;
    height: 150vh;
    right: -10%; 
  }
}

.typing-demo {
  width: 100%;
  animation: typing 2s steps(22), blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
}

@keyframes typing {
  from {
    width: 0
  }
}

@keyframes blink {
  50% {
    border-color: transparent
  }
}

@media screen and (max-width:991px) {
  .progress-bar-quote {
    max-width: 90% !important;
  }

  .quote-title {
    text-align: center !important;
    margin-top: 100px !important;
    font-size: 24px !important;
  }

  .question-form-title {
    font-size: 24px !important;
  }

  .btn-next {
    font-size: 24px !important;
  }

  .px-10 {
    min-height: fit-content;
  }

  .btn-next-parent {

    margin-top: 70px !important;
  }

  .other-box {
    min-height: fit-content !important;
    padding-bottom: 100px !important;
  }

  .px-10 {
    padding: 10px !important;
  }

  .question-form-group {
    width: 100% !important;
  }

  .pt-form-title {
    padding-top: 50px !important;
  }

  .end-page {
    padding-top: 102px !important;
    height: auto !important;
  }

  .end-page-card {
    padding: 41px 30px !important;
    width: 80% !important;
  }

  .end-page-text {
    font-size: 24px !important;
    text-align: center;
    padding: 0 30px !important;
  }

  .end-page-text-2 {
    text-align: center;
    font-size: 13px !important;
    font-family: Blinker-Light;
    line-height: 19px;
  }

  .flex-image {
    width: 150px !important;
    padding-top: 20px !important;
    align-items: center;
    height: 150px !important;
    justify-content: center;
  }

  .flex-branding-items {
    /* justify-content: left; */
    width: 100% !important;
    font-size: medium;
  }

  .quote-title {
    margin-top: 80px !important;
    text-align: center !important;
  }

  .form-business-flex {
    flex-direction: column;
    gap: 0 !important;
    margin-bottom: 20px !important;
  }

  .question-form {
    width: 100% !important;
    margin-top: 0 !important;
    padding-bottom: 20px !important;
  }
}

@media screen and (max-width:767px) {
  .progress-bar-quote {
    max-width: 95% !important;
  }
}

@media screen and (max-width:639px) {
  .section1-background {
    width: 100% !important;
    right: 0% !important;
  }
}

.bg-dark {
  background: #182233;
}

.bg-second {
  background-color: rgba(16, 249, 177, 1);
}

.header-item {
  padding: 0 10px;
}

.header-item a {
  color: #DEE2E6;
  font-weight: 300 !important;
  font-family: Blinker-Light !important;
}
.header-item a:hover {
  color: #fff;
  font-weight: 600 !important;
  font-family: Blinker-Light !important;
}


.section1-background {
  background: url(./images/71.png);
  /* width: 50%; */
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  

  /* right: -25%; */
  /* right: 50%; */
  /* top:30px */
  /* overflow: hidden; */
}

.swiper-slide-home {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */
  /* height: 100dvh; */
  /* Center slide text vertically */
  /* display: flex;
  justify-content: center;
  align-items: flex-start !important; */
}



.section1-content {
  backdrop-filter: blur(12px);
  width: 100%;
  /* margin-left: 50px !important; */
  /* margin-left: 100px; */
  min-height: 130vh !important;
  height: fit-content;
  
}

.section1-card-swiper-item {
  color: #FFF;
  border-radius: 16px;
  background: linear-gradient(224.65deg, rgba(187, 198, 243, 0.11) -17.42%, rgba(0, 0, 0, 0.04) 132.65%);
  padding: 25px 40px;
  /* direction: ltr; */
  transition: 0.3s;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
}

.swiper-item-content-border .swiper-item-content-line-hover {
  height: 20px;
  transition: 0.3s;
}

.section1-card-swiper-item:hover {
  border: 0.5px solid #10F9B1;
  background: linear-gradient(224.65deg, rgba(16, 249, 177, 0.15) -17.42%, rgba(0, 0, 0, 0.04) 132.65%);

  .swiper-item-content-border .swiper-item-content-line-hover {
    height: 170px !important;
  }
}
.section1-card-swiper-item:hover .hover-green {
  color:  #10F9B1 !important;
  /* background: linear-gradient(224.65deg, rgba(16, 249, 177, 0.15) -17.42%, rgba(0, 0, 0, 0.04) 132.65%); */

  
}

.accordion-section1 {
  background: transparent !important;
  box-shadow: none !important;
  color: #FFF !important;
  border-radius: 0 !important;
}

.accordion-section1::before {
  background-color: transparent !important;
}

.bg-glass-1 {
  
  /* border-radius: 16px; */
  padding: 25px 30px;
  font-family: Blinker-Regular !important;
  /* min-height: 535px; */
  /* height: auto !important; */
}

.ff-regular {
  font-family: Blinker-Regular !important;
}

.accordion-bullet {
  min-width: 5px;
  max-width: 5px;
  min-height: 5px;
  max-height: 5px;
  background-color: #10F9B1;
  border-radius: 50%;
}

.accordion-line {
  background-color: #fff;
  width: 1px;
  margin-top: -66px;
  margin-left: -2.5px;
  position: absolute;
  left: 21px;
  transition: 0.3s;
}

.ff-thin {
  font-family: Blinker-Thin !important;
}

.ff-bold {
  font-family: Blinker-Bold !important;
}

.slide-services {
  margin-top: -87px !important;
}

.faq-top-options {
  box-shadow: 0px 5px 12px 0px #6370838C;
  height: fit-content !important;
}

.active-faq-option {
  color: #182233 !important;
  box-shadow: 0px 2px 2px 0px #0000003B !important;
  background-color: #fff;
  transition: 0.3s;
}

.custom-transition {
  transition: 0.3s !important;
}

.w3-animate-opacity {
  animation: opac 0.8s
}

@keyframes opac {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

#next-section {
  margin-top: 10px;
  height: 60px;
}

.section1-card-swiper {
  /* margin-left: -150px; */
}

@media screen and (max-width:640px) {
  .section1-content {
    margin-left: 0 !important;
    min-height: 60vh !important;
    height: auto !important;
  }
}

@media screen and (max-width:1280px) {
  .footer-link {
    font-size: 16px !important;
  }
}

@media screen and (max-width:1280px) {
  .footer {
    display: none;
  }

  .footer-mobile {
    display: block;
  }
}

.accordion {
  background-color: #243146;
  padding: 25px;
  border-radius: 22px;
  transition: 0.3s;
  /* width: 100%; */
  margin: 5px 0;
}

.accordion-top-text {
  font-family: Blinker-Regular;
  color: #FFFFFF;
  width: 75%;
}

.accordion-top-btn {
  background-color: #10F9B1;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.accordion-content {
  transition: 13s;
}

.open-accordion {
  transition: 0.3s;
}

.close-accordion {
  transform: rotate(180deg);
  transition: 0.3s;
}

.w-47 {
  width: 47%;
}

.section1-services {
  backdrop-filter: blur(51px) !important;
  transform: rotate(90deg) !important;
}

.footer-link {
  font-size: 26px;
  font-weight: 400;
  text-align: left;
}

.footer-link-p {
  font-size: 18px;
  font-weight: 400;
  padding-left: 37px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 28px;
  width: 360px;
  padding-right: 25px;
}

.bg-footer-1 {
  background: url('../public/Flags/Flag-3.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.bg-footer-2 {
  background: url('../public/Flags/bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.bg-footer-3 {
  background: url('../public/Flags/bg-2.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.bg-services-2 {
  background: url(./images/71.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center 100px;
  backdrop-filter: blur(21px) !important;
}

.border-dd {
  border: 5px solid #fff;
  width: 500px;
  height: 500px;
  border-radius: 50%;
}

.box {
  /* width: 300px !important; */
  /* height: 100px !important; */
  /* background-color: #fff !important; */
}

.transition-33 {
  transition: 1s;
}

.z-1000 {
  z-index: 100000000 !important;
}

#swiper-slide.swiper-slide-active #swiper-card {
  top: 55% !important;
  margin-top: 0 !important;
  opacity: 1 !important;
  /* transform: rotate(0deg) !important; */
  display: flex !important;
}

#swiper-slide #swiper-card {
  display: none;
}

#swiper-slide {
  background: none;
  opacity: 0 !important;
  /* transform: rotate(-45deg) !important; */
}

#swiper-slide.swiper-slide-active {
  opacity: 1 !important;
  /* transform: rotate(0) !important; */
}

#swiper-slide.swiper-slide-active #back {
  background-repeat: no-repeat !important;
  background-size: 20% 45% !important;
  background-position: center !important;
  z-index: 10;
}

#swiper-slide #back {
  background-repeat: no-repeat !important;
  background-size: 20% 45% !important;
  background-position: center !important;
  /* background: none; */
}

.swiper-slide-active #circle {
  width: 140px;
  height: 140px;
}

.swiper-slide-active #text {
  display: block !important;
}

.overflow-1 {
  overflow: hidden !important;
}

@media screen and (max-width:1024px) {
  .footer-link-p {
    font-size: 14px !important;
  }
}

.section4-business-types {
  border-top-left-radius: 28px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 28px;
  border-bottom-right-radius: 28px;
  padding: 24px 50px;
}

.section4-types-title1 {
  margin-bottom: -5px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 0;
}

.shadow-active-sec4 {
  box-shadow: 0px 2px 2px 0px #0000003B;
}

.shadow-sec4-types-title {
  box-shadow: 0px 0px 12px 0px #DEE2E6A6;
}

.shadow-card-sec4 {
  box-shadow: 0px 4px 4px 0px #63708380;
}

#active-11 {
  /* background-color: #10F9B1 !important; */
  color: #182233 !important;
  transition: 0.5s !important;
  /* border-radius: 100px !important; */
}

.back-sec4-types {
  background-color: #BC83F5 !important;
  color: #182233 !important;
  transition: 0.5s !important;
  border-radius: 100px !important;
  width: 46.5%;
  height: 50px;
  position: absolute;
  z-index: 1;
  left: 5px;
}

.back-sec4-types-2 {
  background-color: #BC83F5 !important;
  color: #182233 !important;
  transition: 0.5s !important;
  border-radius: 100px !important;
  width: 45%;
  height: 35px;
  position: absolute;
  z-index: 1;
  left: 5px;
}

#active-back {
  transform: translateX(112%);
  left: auto !important;
  transition: 0.5s !important;
  background-color: #10F9B1 !important;
}

#active-back-2 {
  transform: translateX(112%);
  left: auto !important;
  transition: 0.5s !important;
  background-color: #10F9B1 !important;
  height: 35px;
}

.active-22 {
  background-color: #BC83F5 !important;
  color: #182233;
  transition: 0.5s !important;
}

#active-33 {
  color: #10F9B1 !important;
  transition: 0.5s !important;
}

#active-55 {
  color: #D9B4FE !important;
  transition: 0.3s !important;
}

.r5px {
  right: 4px !important;
  left: auto !important;
  transition: ease 0.5s !important;
}

.text-black {
  color: #01080A !important;
}

.bdrs-50 {
  border-radius: 50%;
}

.bdrs-full {
  border-radius: 1000px;
}

.sub-option-item:hover {
  border: 1px solid #10F9B1;
  box-shadow: 0px 3px 3px 0px #00000017;
  /* padding: 5px 15px; */
  cursor: pointer !important;
}

.sub-option-item {
  border: 1px solid #637083;
  box-shadow: 0px 3px 3px 0px #00000017;
  padding: 7px 15px;
  font-size: 20px !important;
  cursor: pointer !important;
}

@media screen and (max-width:1023px){
  .sub-option-item {
    
    padding: 3px 7px !important;
    font-size: 16px !important;
    
  }
}

@media screen and (max-width:700px){
  .sub-option-item {
    
    padding: 3px 7px !important;
    font-size: 13px !important;
    
  }
}

#z-1000 {
  z-index: 40000 !important;
}

#active-44 {
  background-color: #fff !important;
  color: #01080A;
  transition: 0.3s !important;
}

.h-custom {
  height: 799px !important;
  max-height: 800px !important;
}

.sec4-price {
  box-shadow: 0px 5px 12px 0px #637083;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
  /* border-bottom-left-radius: 28px; */
  border-bottom-right-radius: 28px;
}

.retail-price-text {
  font-size: 24px;
  font-family: Blinker-Regular !important;
  font-weight: 400;
  color: #10F9B1;
}

.startup-price-text {
  font-size: 24px;
  font-family: Blinker-Regular !important;
  font-weight: 400;
  color: #D9B4FE;
}

.retail-price-text-2 {
  font-size: 15px;
  font-family: Blinker-Regular !important;
  font-weight: 400;
  color: #10F9B1;
}

.startup-price-text-2 {
  font-size: 15px;
  font-family: Blinker-Regular !important;
  font-weight: 400;
  color: #D9B4FE;
}

.sec4-types-flex {
  overflow-x: auto;
}

.sec4-type-title-mobile {
  border-top-left-radius: 36px;
  border-top-right-radius: 36px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0px 0px 4px 0px #DEE2E666;
}

#custom-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  cursor: pointer;
}

/* Track */
#custom-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
#custom-scroll::-webkit-scrollbar-thumb {
  background: grey;
  cursor: pointer;
}

.custom-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  cursor: pointer;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
  background: grey;
  cursor: pointer;
}

.custom-scroll-2::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  cursor: pointer;
  background-color: transparent;
}

/* Track */
.custom-scroll-2::-webkit-scrollbar-track {
  /* background-color: transparent; */
}
.custom-scroll-2 {
  scrollbar-width: thin;
  scrollbar-color: #888 #ccc; /* Customize the scrollbar color */
}

/* Handle */
.custom-scroll-2::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}

#active-66-mobile {
  background-color: #182233;
  padding: 16px;
  box-shadow: 0px 0px 1px 0px #10F9B1;
  border-radius: 20px;
  color: #10F9B1;
  font-weight: 600;
}

#active-77-mobile {
  background-color: #182233;
  padding: 16px;
  box-shadow: 0px 0px 1px 0px #9951E1;
  border-radius: 20px;
  color: #BC83F5;
  font-weight: 600;
}

.proposal-btn-retail {
  font-family: Blinker-Regular !important;
  font-size: 16px !important;
  font-weight: 400;
  text-align: center;
  border-radius: 4px !important;
  padding: 10px 16px;
  background-color: #10F9B1;
  box-shadow: 0px 1px 20px 0px #1BE4A6A6;
  color: #182233;
}

.proposal-btn-startup {
  font-family: Blinker-Regular !important;
  font-size: 16px !important;
  font-weight: 400;
  text-align: center;
  border-radius: 4px !important;
  padding: 10px 16px;
  background-color: #AD68F2;
  box-shadow: 0px 1px 20px 0px #9951E1A6;
  color: #FFFFFF;
}

.proposal-btn-startup-2 {
  font-family: Blinker-Regular !important;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  border-radius: 5px !important;
  /* padding: 5px 25px; */
  background-color: #9951E1 !important;
  width: 182px !important;
  height: 52px !important;
  color: #FFFFFF !important;
  text-transform: none !important;
}

.proposal-btn-retail-2 {
  font-family: Blinker-Regular !important;
  font-size: 20px;
  /* font-weight: 00 !important; */
  text-align: center;
  border-radius: 5px !important;
  /* padding: 5px 25px; */
  background-color: #10F9B1;
  color: #000000CC;
  width: 182px;
  height: 52px;
  text-transform: none !important;
}

.shadow-custom-1 {
  box-shadow: 0px 0px 45px 35px #00000017;
}

.shadow-custom-2 {
  box-shadow: 0px 0px 45px 35px #00000017;
}

.seo-sec2-background {
  background: url('../public/Services/sec-section2.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.seo-sec2-background-2 {
  background: url('../public/Services/seo-section2-back-2.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.web-sec2-background-2 {
  background: url('../public/Services/webD/sec2/image.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.content-background {
  background: url('../public/Services/content/sec2/blog.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.ads-sec1-background {
  background: url('../public/Services/ads/sec1.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
}
.brand-background {
  background: url('../public/Services/brand/sec2/back.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
  
}

.MuiInputBase-formControl {
background-color: rgba(211, 211, 211, 0.1) !important;
border-radius: 5px !important;
border:1px solid rgba(222	,226	,230, 0.3) !important;
}
.css-pvttv9-MuiFormLabel-root-MuiInputLabel-root{
 color: rgb(212, 212, 212) !important;
 
}
.customBtnStyle{
  width: 100%;
  max-width: 266px;
  height: 100%;
  max-height: 52px;
  color: #000 !important;
  background-color: #10F9B1 !important;
  box-shadow:0px 6px 20px #10F9B1;
  font-family: blinker !important;
  font-weight: 400 !important;
  font-size: 15px !important;
}

@media screen and (max-width:991px) {

  .image{
    display: block !important;
    text-align: center !important;
    width: 100% !important;
  }
  .image>img{
    display: block !important;
    text-align: center !important;
    width: 250px !important;
  }

}



.custom-bread{
  color: white !important;
  position: absolute !important;
  /* margin-top:90px !important; */
  top: 15%;
  left:10%;
}
.MuiBreadcrumbs-li>p{
  color: white !important;
}
.go3046353551{
  overflow-y: hidden !important;
}


@media screen and (max-width:991px) {
  .ticket-back{
   
    background: url('../public/ticket.png') ;
  /* background-repeat: no-repeat; */
  /* background-position: center !important; */
  /* background-size: contain; */
  
  }
  .customAccordion{
    width: 289px !important;
    min-height:90px !important;
    background-color:transparent !important;
    /* line-height: 50%; */
    justify-items: center !important;
    margin-bottom: 24px;
    border: 1px solid #ffffff20;
    
    display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
      justify-content: center !important;
      
  }
  .customAccordion  p {
    color: white;
    /* margin-left: 8px;  */
    
    height: 100%;
  }
  .customAccordion {
    transition: height 0.3s ease;
  }
}

.ticket-back{
  
  background: url('../public/ticket.png') ;
background-repeat: no-repeat;
background-position: left;
background-size: contain;
display: flex;
flex-direction: column;
align-items: center;
}

.css-1upxcsk-MuiFormLabel-root-MuiInputLabel-root{
  color:#ffffffb9 !important
}
.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input{
  color: white !important;
}

.customAccordion{
  width: 540px;
  min-height:100px ;
  background:rgba(255, 255, 255, 0.05) !important;
  line-height: 50%;
  justify-items: center;
  margin-bottom: 24px;
  border: 1px solid #ffffff20;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.customAccordion  p {
  color: white;
  margin-left: 8px; 
  
  height: 100%;
}
.customAccordion {
  transition: height 0.3s ease;
}
.dark-scrollbar {
   /* For Firefox */
   /* scrollbar-width: 60px !important; */
   scrollbar-width: thin;
  scrollbar-color: #10F9B1 #FFFFFF20;
  border-radius: 20px; /* For Firefox */
}

#captchaCustom{
  background-color: #000 !important;
  border: 0px solid #fff !important;
}

.brand-card {
  position: relative;
  border-radius: 12px;
  padding: 82px 68px;
  background-color:rgba(0, 0, 0, 0.45); /* رنگ پس‌زمینه */
  text-align: center;
  z-index: 1;
  /* overflow: hidden;  */
  backdrop-filter: blur(20px);
}

.brand-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px; 
  padding: 2px;
  background: linear-gradient(226.1deg, #657CDE 7.25%, #a6b1e2 50.21%, #657CDE 94.47%);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}

.lazy-image {
  width: 100%;
  height: auto;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.lazy-image.loaded {
  opacity: 1;
}
.web-sec2-card {
  position: relative;
  border-radius: 12px; /* شعاع حاشیه برای کارت اصلی */
  padding: 92px 68px;
  background-color:rgba(0, 0, 0, 0.16); /* رنگ پس‌زمینه */
  text-align: center;
  z-index: 1;
  overflow: hidden; /* برای برش زدن حاشیه گرادیان */
  backdrop-filter: blur(20px);
}

.web-sec2-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px; 
  padding: 2px;
  background: linear-gradient(226.1deg, #9952E1 7.25%, #cba3f3 50.21%, #9952E1 94.47%);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}

.seo-sec2-card {
  position: relative;
  border-radius: 12px; /* شعاع حاشیه برای کارت اصلی */
  padding: 92px 68px;
  background-color:rgba(0, 0, 0, 0.16); /* رنگ پس‌زمینه */
  text-align: center;
  z-index: 1;
  overflow: hidden; /* برای برش زدن حاشیه گرادیان */
  backdrop-filter: blur(20px);
}

.seo-sec2-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px; 
  padding: 2px;
  background: linear-gradient(226.1deg, #47F474 7.25%, #BBFCCB 50.21%, #47F474 94.47%);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}

.ads-sec2-card {
  position: relative;
  border-radius: 12px; /* شعاع حاشیه برای کارت اصلی */
  padding: 42px 30px;
  background-color:rgba(0, 0, 0, 0.16); /* رنگ پس‌زمینه */
  text-align: center;
  z-index: 1;
  /* overflow: hidden; */
  backdrop-filter: blur(20px);
}

.ads-sec2-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px; 
  padding: 2px;
  background: linear-gradient(226.1deg, #31B6D5 7.25%, #99ddec 50.21%, #31B6D5 94.47%);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}

.content-card {
  position: relative;
  border-radius: 12px; /* شعاع حاشیه برای کارت اصلی */
  padding: 82px 68px;
  background-color:rgba(0, 0, 0, 0.16); /* رنگ پس‌زمینه */
  text-align: center;
  z-index: 1;
  /* overflow: hidden;  */
  backdrop-filter: blur(20px);
}

.content-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px; 
  padding: 2px;
  background: linear-gradient(226.1deg, #10F9B1 7.25%, #78fad1 50.21%, #10F9B1 94.47%);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}
@-webkit-keyframes autofill {
  0%,100% {
      color: #666 !important;
      background: transparent !important;
  }
}

input:-webkit-autofill {
  -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
  -webkit-animation-name: autofill !important;
  -webkit-animation-fill-mode: both;
}
input:-webkit-autofill { 
  -webkit-background-clip: text;
  -webkit-text-fill-color: #fff;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.np-button{
  width: 210px !important;
  height: 60px !important;
  background-color: #10F9B1 ;
  color: #000 !important;
  font-size: 25px !important;
  font-weight: 400 !important;
  
  border-radius: 5px !important;
  text-transform: none !important;
  /* margin-right: 200px !important; */
}
@media screen  and (max-width:700px){
  .np-button{
    font-size: 16px !important;
    width: 124px !important;
    height: 35px !important;  
  }
}
@keyframes fadeIn {
  0% { opacity: 0; }
  30% { opacity: 1; }
}
/* @keyframes fadeIn {
  0% { transform: rotate(0deg); }
  30% { transform: rotate(360deg); }
} */


.fade-in {
  animation: fadeIn 2s forwards;
  /* transition: 1s ease; */
}

.go591790177{
  /* color: red !important; */
  width: 900px !important;
}
@media  screen and  (max-width:900px){
  .go591790177{
    /* color: red !important; */
    width: 600px !important;
  }
}
@media  screen and  (max-width:644px){
  .go591790177{
    /* color: red !important; */
    width: 300px !important;

  }
}

@media  screen and  (max-width:430px){
  .go591790177{
    /* color: red !important; */
    width: 250px !important;
    /* background-color: red !important; */
  }
}

/* .mobile-container {
  height: 100vh;
  /* overflow: hidden; */
/* } */

/* .notActiveSec {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}  */
.go3073557368 , .go1716137461 {
  border: 1px solid #10F9B1;
  background-color: #2A2F3B;
  padding: 10px;
  border-radius: 8px;
  
  
}
.go135427820{
  margin: 5px !important;
  
}
.go4247048326 .day-tile::after{
  background-color: #182233 !important;
  border-radius: 8px;
  /* max-height: 45px !important; */
  /* margin-top: -40px; */
}
.go4247048326 .active-day-tile::after{
  /* border: 2px solid #10F9B1; */
  background-color: #637083 !important;
}
.active-day-tile{
  color: rgb(232,233,235) !important;
}
/* .go4247048326 .react-calendar__tile--rangeBothEnds::after{
  background-color: #10F9B1 !important;
} */
.react-calendar__tile--active{
  /* color: black !important; */
  color: white !important;
}
.go4247048326 .day-tile{
  max-height: 50px !important;
}
.react-calendar__month-view__weekdays__weekday{
  background-color: rgb(24, 34, 51,0.4) !important;
  /* width:50px !important; */
  /* height: 49px !important; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-family: Blinker !important;
}
.react-calendar__month-view__weekdays__weekday abbr{
  /* font-size: 17px !important; */
}
.go2080639341{
  margin: 20px 0px !important;;

}
@media  screen and  (max-width:500px){
  .react-calendar__month-view__weekdays__weekday{
    /* width:42px !important; */
    /* height: 41px !important; */
    /* background-color: red !important; */
    
  }
  .react-calendar__month-view__weekdays__weekday abbr{
    /* font-size: 13px !important; */
  }
}
.MuiInputBase-input.MuiOutlinedInput-input.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
  border: none !important;
}
.go4114704207.rsm-date-title:nth-of-type(1){
  font-size: 16px;
}
@media (max-width: 430px) {
  .go4114704207.rsm-date-title:nth-of-type(1){
    font-size: 14px;
  }
}
/* response.data.time.response.data.unavalible */
/* @media (max-width: 400px) {
  #logotopmeet {
    margin-left: 10vw;
  }
  #logotopmeet2 {
    width: 90vw;
    margin-left: 10vw;
  }
}
@media (min-width: 401px) and (max-width: 500px) {
  #logotopmeet {
    margin-left: 10vw;
  }
  #logotopmeet2 {
    width: 64vw;
    margin-left: 11vw;
  }
}

@media (min-width: 501px) and (max-width: 650px) {
  #logotopmeet {
    margin-left: 1vw;
  }
  #logotopmeet2 {
    width: 90vw;
    margin-left: 10vw;
  }
} */

/* @media (min-width: 1024px) and (max-width: 1350px) {
  .go1716137461{
    margin-top: 50vh !important;
    width:300px !important ;
  }
} */

.videoPlayer{
  padding-top: 0 !important;
}
.videoSection{
  border-radius: 12px;
  box-shadow: 0 0px 10px #10F9B150;
}

.rsm-cancel-button{
  display: none !important;
}
.rsm-confirm-button{
  background-color: rgba(99, 112, 131, 0.7) !important;
  /* background-color: #637083 !important; */
  border: 1px solid #10F9B1 !important;
  /* height: 50px; */
  padding: 10px !important;
}
.go2556357651 {
  background-color: rgb(255, 255, 255, 0.9) !important;
  margin: 5px 0;
}
.go1318713726 {
  margin: 5px 0;
}
/* .rsm-confirm-button{
  background-color: rgba(99, 112, 131, 0.5) !important;
  border: 1px solid #10F9B1 !important;
} */
.header-image2 {
  background: url('./images/404.png');
  background-size: cover;
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 115vh;
  right: -1%;
  opacity: 90%;
}
@media (max-width: 900px) {
  .header-image2 {
    width: 140vw;
    height: 100vh;
    right: -20%; 
  }
}
#calendarmode{
  border: 0 !important;
}
/* @media (min-width: 1690px) {
  #uui5{
    width: 88% !important ;
  }
}
@media (min-width: 1820px) {
  #uui5{
    width: 89% !important ;
  }
} */
@media (min-width: 1440px) {
  .go1716137461{
    width: 270px !important ;
  }
  .go3073557368{
    width: 856px !important ;
    height: 420px !important ;
    /* margin-left: 200px !important; */
    /* display:flex !important;
    flex-direction: column;
    justify-content: flex-end !important;  */
  }
  .go321821519{
    display: flex !important;
    justify-content: flex-end !important; 
    position: absolute; 
    top: 0; 
    right: 150px ;
  }
  .go695645437{
    /* background-color: blue !important; */
    /* display: flex !important;
    justify-content: flex-end !important; */
    /* position: absolute; 
    top: 0; 
    right: 150px ; */
    /* margin-left: 100px !important; */
    /* display: none !important; */

  }
  #uui5{
    margin-right: 140px; 
    width: 700px;
  }
  #uui6{
    margin-right: 160px; 
  }
  #uui7{
    top: 540px ;
    right: 166px;
  }
  /* #uui7{
    top: 780px+1vh ;
    left: 230px;
  } */
  .go2225988090{
    margin: 0 20px;
  }

}
@media (min-width: 1024px) and (max-width: 1439px) {
  .go1716137461{
    width: 216px !important ;
    
  }
  .go3073557368{
    width: 650px !important ;
    height: 420px !important ;
  }
  .go321821519{
    display: flex !important;
    justify-content: flex-end !important; 
    position: absolute; 
    top: 0; 
    right: 100px ;
  }
  #uui5{
    margin-right: 76px; 
    /* background-color: red; */
    width: 550px;

  }
  #uui6{
    margin-right: 115px; 
  }
  #uui7{
    top: 530px ;
    right: 115px;
  }
  .go2225988090{
    margin: 0 10px;
  }
}

/* @media (min-width: 1024px) and (max-width: 1142px) {
  .go2225988090 {
    margin: 0;
  }
} */
 
.go2080639341{
  margin: 0 !important;
}
@media (max-width: 767px) {
  .go1716137461{
    /* min-width: 300px !important ; */
    width: 320px !important ;
    
  }
  .go3073557368{
    width: 320px !important ;
    /* height: 420px !important ; */
    
  }
  #uui8{
    top: 910px ;
    /* right: 10px; */
  }
  .go695645437{
    /* background-color: blue !important; */
    /* display: flex !important;
    justify-content: flex-end !important; */
    /* position: absolute; 
    top: 0; 
    right: 150px ; */
    /* margin-left: 100px !important; */
    /* display: none !important; */
    /* max-width: 500px ; */
  }
  .go695645437{
    /* background-color: blueviolet !important; */
    width: 336px !important ;
  }
}


@media (min-width: 768px) and (max-width: 1023px) {
  .go1716137461{
    width: 275px !important ;
    /* background-color: rgb(32, 32, 100) !important; */
    /* background-color: red !important; */
    position: absolute !important;
    top: 490px ;
    left: -285px ;
    z-index: 99999 !important;
    height: 350px !important ;
    overflow: visible !important;
    
  }
  .go3073557368{
    width: 300px !important ;
    /* height: 420px !important ; */
    /* background-color: rgb(91, 23, 23) !important; */
    /* background-color: red !important; */
    height: 480px !important ;
    position: relative !important;
    z-index: 0 !important;
    overflow: visible !important;
    
  }
  #uui8{
    top: 1100px ;
    /* right: 10px; */
  }
  .go695645437{
    /* background-color: rgb(71, 35, 105) !important; */
    position: relative !important;
    /* overflow: visible !important; */
    width: 336px !important ;
    height: 880px !important ;
    z-index: 0 !important;
    overflow: visible !important;
  }
  .go1012633899{
    /* overflow: visible; */
    position: relative !important;
    overflow: visible !important;
    z-index: 0 !important;
  }
  .go321821519{
    overflow: visible !important;
  }
  
  .go4247048326 .day-tile::after{
    background: rgb(99, 112, 131,0.2);
    width: 38.5px;
    /* height: 41px; */
    /* background-color: red !important; */
    /* margin: 0px !important; */
    /* padding: 0px !important; */
  }
  /* .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend.day-tile.active-day-tile{
    height: 41px !important;
  } */

}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-multiline.css-9o3wqg-MuiInputBase-root-MuiOutlinedInput-root{
  height: 150px;
}



.MuiInputBase-input.MuiOutlinedInput-input.css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input{
  border: none !important;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.css-add1e1-MuiInputBase-root-MuiOutlinedInput-root{
  border: none !important;
  background-color: red !important;
}
.MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.css-cmpglg-MuiFormControl-root-MuiTextField-root{
  border: none !important;
}
  

  /* .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.css-add1e1-MuiInputBase-root-MuiOutlinedInput-root{
    border: none !important;
    border-radius: 40px !important;
    background-color: red !important;
  }
  .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.css-cmpglg-MuiFormControl-root-MuiTextField-root{
    border: none !important; 
    border-radius: 40px !important;
  }
  .MuiInputBase-input.MuiOutlinedInput-input.css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input{
    border: none !important;
  } */
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.css-pqhry1-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 40px !important;
  }

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.css-570vtq-MuiInputBase-root-MuiOutlinedInput-root{
  /* border: none !important; */
  border-radius: 8px !important; 
}
.MuiInputBase-input.MuiOutlinedInput-input.css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input{
  border: none !important;
}
.go1923315045.rsm-arrow-button {
  position: relative; 
  z-index: 9999;
}
.MuiInputBase-input.MuiOutlinedInput-input.css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input{
  /* color: #000 !important; */
  /* background-color: red; */
}



@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
