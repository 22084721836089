/* Floating Button */
.float-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #51F6C3;
  color: #1A2334;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

/* Modal Overlay (transparent to detect clicks) */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0); /* Transparent background */
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 999;
  padding: 20px;
}

/* Modal positioned at the bottom right */
.modal {
  background-color: #2c323d;
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 280px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 80px;
  right: 20px;
}

/* Modal Options */
.modal-option {
  padding: 10px;
  margin: 10px 0;
  background-color: #1A2334;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.modal-option:hover {
  background-color: #51F6C3;
  color: white;
}

/* Responsive Design */
@media (max-width: 768px) {
  .modal {
      width: 80%;
      right: 10px;
  }
}

@media (max-width: 500px) {
  .modal {
      width: 90%;
      right: 10px;
  }
}
